import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const constantRoutes = [
  {
    path: '/',
    name: 'index-page',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/index/index.vue'),
  },
  {
    path: '/map',
    name: 'map',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/map/index.vue'),
  },
  {
    path: '/bdmap',
    name: 'bdmap',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/map/baiduMap.vue'),
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path: '/list',
    name: 'list',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/list/index.vue'),
  },
]

export default new Router({
  mode: 'history', // 去掉url中的#
  base: '/park/',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes,
})
